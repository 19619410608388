.totalText {
    text-align: right;
  }


  .headingText {
    font-family: "DejaVu Sans", "Arial", sans-serif;
    text-align: center;
    font-size: 50px;
  }

  @font-face {
    font-family: "DejaVu Sans";
    text-align: center;
    font-size: 50px 50px;
    src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
  }



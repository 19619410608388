.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px 5px 5px;
  line-height: 20px;
  right: -20px;
  top: -20px;
  font-size: 22px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.facebook-button {
    background-color: #3B5998;
    color           : white;

    width          : 255px;
    padding        : 12px;
    border         : none;
    border-radius  : 4px;
    margin         : 5px 0;
    opacity        : 0.85;
    display        : inline-block;
    font-size      : 17px;
    line-height    : 20px;
    text-decoration: none;

    /* padding    : 1pt;
    font-size  : large;
    color      : black;
    display    : inline-block;
    height     : 30px;
    width      : auto;
    font-family: Arial, Helvetica, sans-serif; */
}

.google-button {
    background-color: #dd4b39;
    color           : white;
    width           : 255px;
    padding         : 12px;
    border          : none;
    border-radius   : 4px;
    margin          : 5px 0;
    opacity         : 0.85;
    display         : inline-block;
    font-size       : 17px;
    line-height     : 20px;
    text-decoration : none;

    /* 
    font-size  : large;
    color      : black;
    display    : inline-block;
    height     : 30px;
    width      : auto;
    font-family: Arial, Helvetica, sans-serif; */
}


.linkedin-button {
    background-color: #0077B5;
    color           : white;

    width          : 255px;
    padding        : 12px;
    border         : none;
    border-radius  : 4px;
    margin         : 5px 0;
    opacity        : 0.85;
    display        : inline-block;
    font-size      : 17px;
    line-height    : 20px;
    text-decoration: none;

    /* font-size  : large;
    color      : black;
    display    : inline-block;
    height     : 30px;
    width      : auto;
    font-family: Arial, Helvetica, sans-serif; */
}


.facebook-button:hover,
.google-button:hover,
.linkedin-button:hover {
    cursor : pointer;
    opacity: 1;
}


.social-login-container {
    text-align  : center;
    padding-left: 20px;

    /* width      : 50vw; */
}

.social-media-logo {
    padding-right: 10px;
}
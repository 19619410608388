.Unread {
    font-weight: 700;
}

.Expired {
    text-decoration: line-through;
}

.normal {
    font-weight: initial;
}
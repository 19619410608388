.Content-logo {
    margin: 60px 0 0 0;
}

.select-before {
    width: 200px;
}

.Content-KnowledgeBase {
    font-size    : 22px;
    margin-bottom: 50px;
}

.Content-SearchBar {
    margin-top   : 50px;
    margin-bottom: 25px;
}

.Content-InfoText {
    margin-top: 25px;
}

.landing-page {
    padding-bottom: 6vh;
}
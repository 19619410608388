.masterSearchBar {
    /* margin-top: 10px; */
}

.masterSearchBar .ant-input {
    border-radius: 0px;
}

.masterSearchBar .ant-btn {
    /* border-radius    : 0px; */
    border           : solid 1px #bfbfbf;
    border-left-width: 0px;
}